<template>
  <div class="">
    <div class="max-width">
      <div ref="observerTarget">
        <CardSingle
          v-if="!isDesktop"
          :is-loading="isLoading"
          :see-more-link="getSlug"
          :image="imageCoverUrl"
          :title="punchLine"
          :sub-title="title"
          :template="sectionTemplate"
          :description="tagLine"
          @on-group-clicked="(data: HomepageModelEvent) => emits('on-group-clicked', data)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useIntersectionObserver } from "@vueuse/core";
import { ref, toRefs, watch, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { getHomeSection } from "~/services/common/homeSection";
import useCityStore from "~/stores/city";
import alert from "~/lib/alert";
import { toSlug } from "~/helpers/string";
import { rebuildAssetURL } from "~/helpers/url";
import useClientTypeStore from "~/stores/clientType";
import CardSingle from "~/components/restaurant/RestaurantCard/Type/CardSingle.vue";
import { type HomepageModelEvent } from "~/types/Home";

const props = defineProps({
  apiOrder: {
    type: Number,
    default: 0,
  },
  homeSectionOrder: {
    type: Number,
    default: 0,
  },
  endPointApi: {
    type: String,
    default: "",
  },
  punchLine: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  imageCoverUrl: {
    type: String,
    default: "",
  },
  slug: {
    type: String,
    default: "",
  },
});

const { slug } = toRefs(props);

const emits = defineEmits<{
  (e: "on-group-clicked", data: HomepageModelEvent): void;
}>();
const clientTypeStore = useClientTypeStore();
const { isDesktop } = storeToRefs(clientTypeStore);
const cityStore = useCityStore();
const { selectedCityId } = storeToRefs(cityStore);
const { apiOrder, endPointApi } = toRefs(props);
const observerTarget = ref(null);
const isVisible = ref(false);
const isDataLoaded = ref(false);
const icon = ref("");
const tagLine = ref("");
const sectionTemplate = ref("");
const isLoading = ref(true);
const options = {
  root: null,
  rootMargin: "0px",
  threshold: buildThresholdList(),
};

const { stop } = useIntersectionObserver(
  observerTarget.value,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  },
  options
);

function buildThresholdList() {
  const thresholds = [];
  const numSteps = 20;

  for (let i = 1.0; i <= numSteps; i++) {
    const ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}

const getSlug = computed(() => {
  return slug.value ? toSlug(slug.value) : "";
});

async function fetchData() {
  isLoading.value = true;
  const { data, isSuccess, message } = await getHomeSection({
    order: apiOrder.value,
    endPointApi: endPointApi.value,
  });
  if (!isSuccess || !data) {
    alert.error(message);
    return;
  }
  if (data) {
    icon.value = data.icon ? rebuildAssetURL(data.icon.url) : "";
    tagLine.value = data.tagLine;
    sectionTemplate.value = data.sectionTemplate;
    isLoading.value = false;
    isDataLoaded.value = true;
    stop();
  }
}

onMounted(() => {
  watch(isVisible, (newVal) => {
    if (newVal === true && isDataLoaded.value === false) {
      fetchData();
    }
  });
  watch(endPointApi, () => {
    fetchData();
  });
});
</script>
<script lang="ts">
export default {
  name: "TopBrand",
};
</script>
